import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Immutable from 'immutable';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';

import ProcessNotify from '../ProcessNotify';
import Group from './controls/Group';
import FIELD_TYPES from '../../../../configs/fieldTypes';
import ControlItem from './ControlItem';
import calcVisibleControls from '../../../../utils/calcVisibleControls';
// import createFieldComponentsByType from "./controls/fieldInterfaces/Base";
import FieldApi from '../../../../models/FieldApi/index';

import styles from './controlList.less';
import ControlItemMassUpdate from '../../../RecordsBatch/RecordsBatchUpdate/RecordsBatchUpdateForm/ControlItemMassUpdate';

// beacuse Symbol can't convert to String(error from React)
const virtualGroupId = '$virtualGroupId';

let idPrefix = 0;

class ControlList extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    onHiddenChange: PropTypes.func,
    onEndEditing: PropTypes.func,
    keyForStorage: PropTypes.string,
    fieldsEditableStatus: PropTypes.object,
  };

  ref = createRef();

  key = localStorage.getItem(`controlList.${this.props.keyForStorage}`);

  state = {
    closed: this.key ? JSON.parse(this.key) : {},
    sections: [],
  };

  idPrefix = idPrefix++;

  setSections(configs, values, tabId) {
    let _curGroup;
    let _curTabId;
    let sections = [];
    const closed = {};

    configs.forEach((config) => {
      if (config.get('type') === FIELD_TYPES.GROUP) {
        if (config.getIn(['config', 'tab'])) {
          _curTabId = config.get('id');
        }
        _curGroup = {
          id: config.get('id'),
          tabId: _curTabId,
          section: config,
          configs: [],
          values: {},
        };
        sections.push(_curGroup);
      } else {
        if (!_curGroup) {
          _curGroup = {
            id: virtualGroupId,
            virtual: true,
            tabId: _curTabId,
            section: Immutable.fromJS({ name: '', type: FIELD_TYPES.GROUP }),
            configs: [],
            values: {},
          };
          sections.push(_curGroup);
        }
        if (config.get('error')) {
          closed[_curGroup.id] = false;
        }
        _curGroup.configs.push(config);
      }
    });
    // при выборе варианта конвертации Excel -> JSON убираем секцию параметров
    if (values && values.get('method') === 'excel-json') {
      sections = sections.filter((section) => section.id !== 'section_data'); // это говно перенести в скрипты !!!!!!!!!!!
    }
    // filter by tab
    if (tabId && tabId > 0) {
      sections = sections.filter((s) => s.tabId == tabId);
    }
    this.setState({
      sections,
      closed: {
        ...this.state.closed,
        ...closed,
      },
    });
  }

  toggleList = (sectionId) => {
    const closedSections = {
      ...this.state.closed,
      [sectionId]: !this.state.closed[sectionId],
    };

    this.setState({
      closed: closedSections,
    });

    if (this.props.keyForStorage) {
      localStorage.setItem(`controlList.${this.props.keyForStorage}`, JSON.stringify(closedSections));
    }
  };

  componentDidUpdate() {
    if (this.errorControl && this.errorControl !== this.lastErrorControl) {
      this.errorControl.focus();
      this.lastErrorControl = this.errorControl;
    }
  }

  componentDidMount() {
    const { configs } = this.props.data;
    const { values } = this.props.data;
    const { tabId } = this.props;
    this.setSections(configs, values, tabId);
    this.setHiddenFields(configs, values);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newConfig = nextProps.data.configs;
    const { configs } = this.props.data;

    const newValues = nextProps.data.values;
    const { values } = this.props.data;

    const newTabId = nextProps.tabId;
    const { tabId } = this.props;

    if ((newConfig && newConfig !== configs) || (newValues && newValues !== values) || newTabId !== tabId) {
      this.setSections(newConfig, newValues, newTabId);
      this.setHiddenFields(newConfig, newValues);
    }
  }

  setHiddenFields(configs, values) {
    const visible = calcVisibleControls(values, configs);
    const hidden = {};
    _.mapKeys(visible, (v, fId) => {
      if (!v) {
        hidden[fId] = !v;
      }
    });
    const oldHidden = this.state.hidden;
    if (!_.isEqual(hidden, oldHidden)) {
      this.setState({ hidden });
      this.props.onHiddenChange && this.props.onHiddenChange(hidden);
    }
  }

  getControllVisibility = (fieldId) => {
    const { fieldsEditableStatus } = this.props;
    const fieldEditableStatus = fieldsEditableStatus && fieldsEditableStatus.get(fieldId);

    return _.isUndefined(fieldEditableStatus) ? false : fieldEditableStatus;
  };

  render() {
    const { props, state } = this;
    const { data, params, className, compact, sceneId, labelOnTop, countingFieldsChanged, onChange, onEndEditing, t } =
      props;
    const { values, configs } = data;
    const { hidden } = state;
    const firstError = configs.find((f) => f.get('error'));

    return (
      <div
        ref={this.ref}
        className={cn(styles.controlList, className, {
          [styles.labelOnTop]: labelOnTop,
        })}
      >
        {state.sections.map((section) => {
          // hide hidden sections
          if (section.id && !section.virtual && hidden[section.id]) {
            return null;
          }

          // hide empty sections
          const hasVisibleControls = !!section.configs.find((control) => !hidden[control.get('id')]);
          if (!hasVisibleControls) {
            return null;
          }

          const sectionVirtual = section.virtual;
          const sectionClosed = state.closed[section.id];

          const singleFieldInSection = section.configs?.length === 1;
          const singleFieldInTab =
            state.sections.length === 1 && singleFieldInSection && section.id !== '$virtualGroupId'; // TODO: сделать для связ каталогов свой режим(Ильяс)
          return (
            <div key={section.id}>
              {!(sectionVirtual && compact) ? (
                <Group
                  params={params}
                  id={section.id}
                  title={section.section.get('name')}
                  closed={sectionClosed}
                  onClick={this.toggleList}
                  count={this.props.t('record.groupFields.count', {
                    count: section.configs.length,
                  })}
                />
              ) : null}

              <div
                className={cn(styles.sectionFields, {
                  [styles.sectionFieldsHidden]: sectionClosed,
                  [styles.sectionFieldsSingleFieldInSection]: singleFieldInSection,
                  [styles.sectionFieldsSingleFieldInTab]: singleFieldInTab,
                })}
              >
                {section.configs.map((controlConfig) => {
                  const config = controlConfig.get('config');
                  const controlId = controlConfig.get('id');
                  if (hidden[controlId]) {
                    return null;
                  }

                  const type = controlConfig.get('type');

                  const currentControlError = firstError && firstError.get('id') === controlId;
                  const htmlId = this.idPrefix + controlId;

                  const editable = !(controlConfig.get('apiOnly') || controlConfig.get('readOnly'));

                  const value = values.get(controlId);
                  const hasValue =
                    value && value.get ? !!value.size : value !== undefined && value !== null && value !== '';

                  const Control = FieldApi.getComponent(controlConfig, 'control');

                  const ControlProps = {
                    id: controlId,
                    containerRef: this.ref,
                    sceneId,
                    type,
                    htmlId,
                    value,
                    controlConfig,
                    config,
                    params,
                    editable,
                    t,
                    hint: controlConfig.get('hint'),
                    eventable: controlConfig.get('eventable'),
                    apiOnly: controlConfig.get('apiOnly'),
                    readOnly: controlConfig.get('readOnly'),
                    updateProcess: controlConfig.get('updateProcess'),
                    error: controlConfig.get('error'),
                    onChange: (value) => onChange(controlId, value),
                    onEndEditing: (value) => onEndEditing(controlId, value),
                  };

                  // canExpandWidth, canExpandWidthAndHeight
                  const extraProps = FieldApi.getCanComponentExpandWidthOrHeight(
                    controlConfig,
                    singleFieldInSection,
                    singleFieldInTab,
                  );

                  return (
                    <ControlItem
                      sceneId={sceneId}
                      fieldId={controlId}
                      labelRef={currentControlError && ((node) => (this.errorControl = node))}
                      key={controlId}
                      htmlId={htmlId}
                      config={config}
                      name={controlConfig.get('name')}
                      required={controlConfig.get('required')}
                      type={type}
                      apiOnly={controlConfig.get('apiOnly')}
                      readOnly={controlConfig.get('readOnly')}
                      hint={controlConfig.get('hint')}
                      updateProcess={controlConfig.get('updateProcess')}
                      error={controlConfig.get('error')}
                      hasValue={hasValue}
                      editable={editable}
                      compact={compact}
                      labelOnTop={labelOnTop}
                      {...extraProps}
                    >
                      {countingFieldsChanged ? (
                        <ControlItemMassUpdate {...props} controlConfig={controlConfig}>
                          <Control {...ControlProps} />
                        </ControlItemMassUpdate>
                      ) : (
                        <Control {...ControlProps} {...extraProps} />
                      )}
                    </ControlItem>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default withTranslation()(ControlList);
